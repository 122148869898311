import * as Parallax from "parallax-js";

// ==================================================================================== //
// PARALLAX

let scenes = document.getElementsByClassName('parallax-scene')

for (let s of scenes) {
    var parallax_instance = new Parallax(s);
}
